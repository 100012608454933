import { send } from './send';
import { ITrackerData } from 'src/types';
import { getFlag, hashCode, setFlag } from 'src/utils/util';

import { ACTION_TYPE } from 'src/constant';

function generateErrorName(type: string, ...args: string[]) {
  const url = window.location.href.replace(/(\?|#).*$/, '');
  const name = type + hashCode(url + args.join('_'));
  return name;
}

export function initTrycatch() {
  if (getFlag('initTrycath')) return;
  setFlag('initTrycath');

  window.addEventListener(
    'xhrError',
    (error: any) => {
      try {
        if (error.detail.status == 401) {
          return;
        }
        const responseText = error.detail.responseText?error.detail.responseText.substring(0, 100).trim():'';
        let body=error.detail.body?error.detail.body.substring(0, 500):'';
        if(body&&/password/.test(body)){
          body=body.replace(/(password.{3})[^"]*/,'$1***隐藏上报****');
        }
        const info = {
          trackId: 'trycatch-xhrError',
          eventName: generateErrorName('xhrError', error.detail.url.replace(/\?.*$/, ''), error.detail.status),
          custom: {
            url: error.detail.url,
            status: error.detail.status,
            method: error.detail.method,
            duration: error.detail.duration,
            responseText: responseText,
            body: body
          }
        };
        generateLog(info);
      } catch (error) {}
    },
    false
  );

  window.addEventListener(
    'error',
    (error) => {
      try {
        let target: any = error.target || error.srcElement;
        let isElementTarget = target instanceof HTMLElement;
        if (isElementTarget) {
          const filename = target.src || target.href;
          const info = {
            trackId: 'trycatch-sourceError',
            eventName: generateErrorName('sourceError', window.location.href.replace(/(\?|#).*$/, '')),
            custom: {
              message: '资源加载失败:' + target.outerHTML,
              filename
            }
          };
          generateLog(info);
        } else {
          const info = {
            trackId: 'trycatch-jsError',
            eventName: generateErrorName('jsError', error.message),
            custom: {
              lineno: error.lineno,
              colno: error.colno,
              stack: error.error?.stack,
              message: error.message,
              filename: error.filename
            }
          };
          generateLog(info);
        }
      } catch (error) {}
    },
    true
  );

  window.addEventListener('unhandledrejection', (error) => {
    try {
      const info = {
        trackId: 'trycatch-unhandledrejection',
        eventName: generateErrorName('unhandledrejection', error.reason?.message),
        custom: {
          stack: error.reason?.stack,
          message: error.reason?.message
        }
      };
      generateLog(info);
    } catch (error) {}
  });
}

function generateLog(info: ITrackerData) {
  //同一错 10s限制上报1次
  let lastEventTime = window.localStorage.getItem('EVENT_TIME' + info.eventName);
  if (lastEventTime && Date.now() - Number(lastEventTime) < 1000 * 10) {
    return;
  }
  window.localStorage.setItem('EVENT_TIME' + info.eventName, String(Date.now()));
  send({ ...info, actionType: ACTION_TYPE.EVENT });
}
